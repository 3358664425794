<template>
  <div class="collect">
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
      v-if="!noData"
    >
    <div class="recommend-ul">
      <div class="recommend-li" v-for="item in recommendList" :key="item.identityId" @click.stop="$router.push({path:'/attendantDetails',query:{id:item.identityId}})">
        <img
          class="serveImgUrl"
          :src="item.avatar"
          alt=""
        />
        <div class="recommend-info">
          <p class="name-status">
            <font class="name">{{ item.nickName }}</font>
          </p>
          <p>
            <van-rate v-model="item.star" size="15" readonly color="orange" />
            {{ item.star || 0 }}
          </p>
          <p class="plane">
            <span class="plane-span"
              ><svg-icon icon-class="plane" class="planeIcon"></svg-icon>
              {{ item.distance || 0 }}km</span
            >
            <svg-icon
              icon-class="collect2"
              class="collectIcon"
              @click.stop="collectClick(item)"
            ></svg-icon>
            <!-- <svg-icon :icon-class="item.collect>3?'collect1':'collect2'" class="collectIcon" @click="collectClick(item)"></svg-icon> -->
          </p>
        </div>
      </div>
    </div>
    </van-list>
    <div class="nullDataDiv" v-if="noData">
      <img src="../../assets/img/error.png" alt="" />
      <span class="text">暂无数据</span>
    </div>
  </div>
</template>

<script>
import { pageCollectArtificer, noCollectionArtificer } from "@/api/my";
import AMapLoader from "@amap/amap-jsapi-loader";
import Location from "@/utils/location";
export default {
  data() {
    return {
      page: 1,
      total: 0,
      lng: "",
      lat: "",
      noData:true,
      loading: false,
      finished: false,
      recommendList: []
    };
  },
  created() {
    this.getMap();
  },
  methods: {
    // 取消收藏点击
    collectClick(item) {
      noCollectionArtificer({ artificerId: item.identityId }).then(res => {
        if (res.data) {
          this.$toast("取消成功");
          this.recommendList = []
          this.pageCollectArtificer();
        }
      });
    },
    // 获取经纬度
    getMap() {
      let _this = this;
      AMapLoader.load({
        plugins: ["AMap.Geolocation"]
      }).then(AMap => {
        Location.getCurrentLocation(
          AMap,
          result => {
            (_this.lng = result ? result.pois[0].location.lng : "116.397455"),
              (_this.lat = result ? result.pois[0].location.lat : "39.919087");
            _this.pageCollectArtificer();
          },
          () => {
            console.log("定位失败");
            _this.pageCollectArtificer();
          }
        );
      });
    },
    // 获取列表数据
    pageCollectArtificer() {
      let data = {
        pageIndex: this.page,
        pageSize: 10,
        lng: this.lng || "116.397455",
        lat: this.lat || "39.919087"
      };
      pageCollectArtificer(data).then(res => {
        this.loading = false;
        this.total = res.data.total;
        if (res.data.records.length > 0) {
          this.recommendList = this.recommendList.concat(res.data.records);
        } else {
          this.finished = true;
        }
        if(this.recommendList.length>0){
          this.noData = false
        }else{
          this.noData = true
        }
      });
    },
    // 分页
    onLoad() {
      if (this.page < Math.floor((this.total + 9) / 10)) {
        this.page++;
        this.pageCollectArtificer();
      } else {
        this.finished = true;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.collect {
  min-height: 100vh;
  background: rgb(244, 244, 244);
  padding: 0.4rem 0.4rem 0;
  display: flex;
  .recommend-ul {
    display: flex;
    flex-wrap: wrap;
    .recommend-li {
      width: 49%;
      background: #fff;
      border-radius: 0.2rem;
      padding: 0.3rem;
      box-sizing: border-box;
      margin-bottom: 0.4rem;
      &:not(:nth-child(2n)) {
        margin-right: 2%;
      }
      .serveImgUrl {
        width: 100%;
        height: auto;
      }

      .recommend-info {
        display: flex;
        flex-direction: column;

        p {
          padding-top: 0.2rem;
        }

        .name-status {
          display: flex;
          align-items: center;

          .name {
            font-size: 0.4rem;
          }

          .status {
            margin-left: 0.2rem;
            background: rgb(0, 219, 113);
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 1rem;
            padding: 0.06rem 0;
            font-size: 0.28rem;
            border-radius: 0px 10px 10px 10px;
          }
        }

        .plane {
          display: flex;
          color: grey;
          justify-content: space-between;
          align-items: center;
          .plane-span {
            display: flex;
          }
          .collectIcon {
            font-size: 0.5rem;
          }
        }
      }
    }
  }
}
</style>
